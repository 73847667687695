import { configureStore } from '@reduxjs/toolkit';
import AuthSlice from './AuthSlice/AuthSlice';
import ProjectsSlice from './ProjectSlice/ProductsSlice';
import StateSlice from './StateSlice/StateSlice';

export const store = configureStore({
	reducer: {
		auth: AuthSlice,
		products: ProjectsSlice,
		all_states: StateSlice,
	},
});
