import { Route, Routes, useNavigate, Navigate } from 'react-router-dom';
import Home from './Pages/Home';
import Login from './Pages/Auth/Login';
import Register from './Pages/Auth/Register';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSelector } from 'react-redux';
import AllProducts from './Pages/Projects/AllProducts';
import Profile from './Pages/Profile/Profile';
import ShowProfile from './Pages/Profile/ShowProfile';
import CreateProduct from './Pages/Projects/CreateProduct';
import ShowProducts from './Pages/Projects/ShowProducts';
import { Logout } from '@mui/icons-material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import EditProduct from './Pages/Projects/EditProduct';

function App() {
	const user = JSON.parse(localStorage.getItem('auth'))?.data;

	const { mainUser } = useSelector((state) => state.auth)

	return (
		<>
			<ToastContainer
				position="top-right"
				autoClose={5000}
				hideProgressBar={false}
				newestOnTop={false}
				closeOnClick
				rtl={false}
				pauseOnFocusLoss
				draggable
				pauseOnHover
			/>
			{mainUser ? (
				<>
					<Routes>
						<Route path="/" element={<Navigate to="/" />} />
						<Route path="*" element={<Navigate to="/" />} />
						<Route path="/" element={<Home />}>
							<Route index element={<Home />}></Route>
							<Route path="products" element={<AllProducts />}>
								<Route index element={<ShowProducts />}></Route>
								<Route path="create" element={<CreateProduct />}></Route>
								<Route
									path="edit/:product_id"
									element={<EditProduct />}
								></Route>
							</Route>
							<Route path="/profile" element={<Profile />}>
								<Route index element={<ShowProfile />}></Route>
							</Route>
						</Route>
					</Routes>
				</>
			) : (
				<>
					<Routes>
						<Route path="*" element={<Navigate to="/"></Navigate>}></Route>
						<Route path="/" element={<Login />}></Route>
						<Route path="/register" element={<Register />}></Route>
						{/* <Route path="/forget-password" element={<ForgetPassword />}></Route> */}
					</Routes>
				</>
			)}
		</>
	);
}

export default App;
