import React, { useEffect, useState } from 'react';
import { FormContainer } from '../../layouts/Styled/buttons/FormContainer';
import { useDispatch, useSelector } from 'react-redux';
import { Formik, useFormik } from 'formik';
import {
	Button,
	FormControl,
	Grid,
	InputAdornment,
	InputLabel,
	MenuItem,
	OutlinedInput,
	Select,
	TextField,
} from '@mui/material';
import { Add, CloudUpload, Create, Mail } from '@material-ui/icons';
import styled from 'styled-components';
import { HeaderStyle2 } from '../../layouts/Styled/HeaderStyle';
import { FormButtonStyle } from '../../layouts/Styled/buttons/submitButton';
import {
	CreateProductMethod,
	productReset,
} from '../../redux/ProjectSlice/ProductsSlice';
import { toast } from 'react-toastify';
import { GetAllStatesMethod, GetAllProductTypesMethod } from '../../Components/HelperMethods/APISMethodsHelper';
import {
	HandleSuccessNotifications,
	HendleErrorsNotifications,
} from '../../Components/HelperMethods/HandleNotifications';

const CreateProduct = () => {
	const dispatch = useDispatch();
	const { message,product_types, isSuccess, isError, isLoading, errors, responseCode } =
		useSelector((state) => state.products);

	const { all_states } = useSelector((state) => state.all_states);

	const AllTypes = product_types?.data;
	const AllStates = all_states?.data?.states;

	const [formSubmit, setFormSubmit] = useState(false);

	const formik = useFormik({
		initialValues: {
			name: '',
			description: '',
			quantity: 0,
			price: 0,
			state: '',
			product_type_id: '',
		},
		onSubmit: async (values) => {
			const form_data = new FormData();
			form_data.append('name', values?.name);
			form_data.append('description', values?.description);
			form_data.append('quantity', values?.quantity);
			form_data.append('price', values?.price);
			form_data.append('state', values?.state);
			form_data.append('product_type_id', values?.product_type_id);
			setFormSubmit(true);
			await dispatch(CreateProductMethod(form_data));
			await dispatch(productReset());
		},
	});

	useEffect(() => {
		GetAllStatesMethod(dispatch);
		GetAllProductTypesMethod(dispatch);
	}, []);

	useEffect(() => {
		if (isError) {
			HendleErrorsNotifications(toast, isError, errors, message, responseCode);
		}

		if (isSuccess && formSubmit) {
			setFormSubmit(false);
			HandleSuccessNotifications(toast, 'Product Create Successfuly');
			formik.resetForm();
		}
	}, [
		dispatch,
		isError,
		isSuccess,
		isLoading,
		formSubmit,
		message,
		errors,
		responseCode,
	]);

	const handleUploadImage = (e) => {
		formik.values.specifications = e.currentTarget.files[0];
	};

	return (
		<CreateContainer>
			<FormContainer onSubmit={formik.handleSubmit}>
				<HeaderStyle2>
					<Add /> Create
				</HeaderStyle2>
				<Grid container spacing={2}>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="name"
							label="Name"
							value={formik.values.name || []}
							onChange={formik.handleChange}
							error={
								formik.touched.name && Boolean(formik.errors.name)
							}
							helperText={
								formik.touched.name && formik.errors.name
							}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							id="input-with-icon-textfield"
							name="description"
							label="Description"
							value={formik.values.description || []}
							onChange={formik.handleChange}
							error={formik.touched.description && Boolean(formik.errors.description)}
							helperText={formik.touched.description && formik.errors.description}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							type="number"
							id="input-with-icon-textfield"
							name="quantity"
							label="Quantity"
							value={formik.values.quantity || []}
							onChange={formik.handleChange}
							error={
								formik.touched.quantity &&
								Boolean(formik.errors.quantity)
							}
							helperText={
								formik.touched.quantity && formik.errors.quantity
							}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<TextField
							fullWidth
							type="number"
							id="input-with-icon-textfield"
							name="price"
							label="Price"
							value={formik.values.price || []}
							onChange={formik.handleChange}
							error={
								formik.touched.price &&
								Boolean(formik.errors.price)
							}
							helperText={
								formik.touched.price && formik.errors.price
							}
						/>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<FormControl style={{width:"100%"}}>
							<InputLabel id="demo-multiple-name-label">State</InputLabel>
							<Select
								labelId="demo-multiple-name-label"
								id="demo-multiple-name"
								name="state"
								value={formik.values.state}
								onChange={formik.handleChange}
								input={<OutlinedInput label="state" />}
							>
								{AllStates?.map((state) => (
									<MenuItem
										key={state.id}
										value={state.abbr}
									>
										{state.name}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<Grid item mb={2} md={6} xs={12}>
						<FormControl style={{width:"100%"}}>
							<InputLabel id="demo-multiple-name-label">Product Type</InputLabel>
							<Select
								labelId="demo-multiple-name-label"
								id="demo-multiple-name"
								name="product_type_id"
								value={formik.values.product_type_id}
								onChange={formik.handleChange}
								input={<OutlinedInput label="product_type_id" />}
							>
								{AllTypes?.map((state) => (
									<MenuItem
										key={state.id}
										value={state.id}
									>
										{state.type}
									</MenuItem>
								))}
							</Select>
						</FormControl>
					</Grid>
					<FormButtonStyle startIcon={<Create />} type="submit">
						Create
					</FormButtonStyle>
				</Grid>
			</FormContainer>
		</CreateContainer>
	);
};

const CreateContainer = styled.div`
	margin: auto;
	width: 50%;
`;

export default CreateProduct;
