import {
	Button,
	Grid,
	ListItem,
	ListItemButton,
	ListItemText,
	Typography,
} from '@mui/material';
import { Box } from '@mui/system';
import React from 'react';
import { NavLink, Outlet } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import styled from 'styled-components';
import { HeaderStyle } from '../../layouts/Styled/HeaderStyle';

function AllProducts() {
	return (
		<ProductContainer>
			<Grid container spacing={2}>
				<Grid item md={10} sm={11}>
					<HeaderStyle>All Products</HeaderStyle>
				</Grid>
				<Grid item md={2} sm={1}>
					<Button className="new-product bg-main" variant="contained">
						<NavLink to="create" className="text-light">
							Create{' '}
						</NavLink>
					</Button>
				</Grid>
				<Grid item md={12}>
					<Outlet></Outlet>
				</Grid>
			</Grid>
		</ProductContainer>
	);
}

const ProductContainer = styled.div`
	.border-main {
		border: 2px solid var(--main-color);
	}
	.new-product {
		margin: 30px 0;
		width: 100%;
	}
`;

export default AllProducts;
