import axios from 'axios';
import { AUTH_URL, USER_AUTH } from '../../layouts/API';

const LoginUserMethod = async (data) => {
	let headers = {
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${AUTH_URL}/login`,
		Headers: headers,
		data: data,
	});
	return response.data;
};

const RegisterUserMethod = async (data) => {
	let headers = {
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'POST',
		baseURL: `${AUTH_URL}/register`,
		headers: headers,
		data: data,
	});

	return response.data;
};

const LogoutUserMethod = async () => {
	let headers = {
		Accept: 'application/json',
	};

	let response = await axios({
		method: 'GET',
		baseURL: `${AUTH_URL}/logout`,
		headers: headers,
	});

	return response.data;
};


const UpdateUserInformationMethod = async (data, token) => {
	let headers = {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`
	};

	console.log(data)

	let response = await axios({
		method: 'POST',
		baseURL: `${USER_AUTH}/update/${data?.user_id}`,
		headers: headers,
		data: data?.data,
	});
	return response.data;
};
const ShowUserInformationMethod = async (data, token) => {
	let headers = {
		Accept: 'application/json',
		Authorization: `Bearer ${token}`
	};

	console.log(data)

	let response = await axios({
		method: 'GET',
		baseURL: `${USER_AUTH}`,
		headers: headers,
		data: data?.data,
	});
	return response.data;
};
const AuthService = {
	LoginUserMethod,
	RegisterUserMethod,
	LogoutUserMethod,
	UpdateUserInformationMethod,
	ShowUserInformationMethod
};

export default AuthService;
