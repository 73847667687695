import styled from 'styled-components';

export const SubmitButton = styled.button`
	width: 100%;
	padding: 10px;
	font-size: 1.3em;
	border-radius: 10px;
	border: 3px solid var(--main-color);
	background: none;
    margin-top: 10px;
	cursor: pointer;
	background: var(--main-color);
	color: var(--light);
`;

export const FormButtonStyle = styled.button`
	width: 100%;
	padding: 10px;
	font-size: 1.3em;
	background: var(--sub-color);
	color: var(--light);
	border-radius: 10px;
	border: none;
	cursor: pointer;
`;

export const SubButton = styled.button`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 12px 20px;
    gap: 8px;
    width: 100%;
	background: #E7EAEE;
    border-radius: 8px;
	cursor: pointer;
	color: #64748B;
	border: 0;
`;

