import styled from 'styled-components';

export const HeaderStyle = styled.h1`
	color: var(--main-color);
	border-bottom: 3px solid var(--main-color);
`;

export const HeaderStyle2 = styled.h2`
    color: var(--sub-color);
    border-left: 5px solid var(--sub-color);
    padding-left: 15px;
`;

export const PopUpstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    padding: 4
};

export const ImagePopUpstyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '70%',
    height: '90%',
    padding: 2
};