import { toast } from 'react-toastify';

export const HendleErrorsNotifications = (
	toast,
	errors,
	message,
	isError = true,
	responseCode = 422,
) => {
	if (errors?.length > 0) {
		errors?.map((error) => {
			toast.error(error.msg);
		});
	} else {
		toast.error(message);
	}
};

export const HandleSuccessNotifications = (
	toast,
	message,
	isSuccess = true,
	RedirectRoute = 200,
) => {
	toast.success(message);
};
