import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import ProductService from './ProductsService';
import { GetUserToken } from '../../Components/HelperMethods/UserToken';
import { GetErrorMessage, GetErrorsObj, GetResponseCode } from '../../Components/HelperMethods/ReturnErrors';

export const GetAllProductsSlice = createAsyncThunk(
	'product/all',
	async (data, thunkAPI) => {
		try {
			let token = GetUserToken(thunkAPI);
			return await ProductService.GetAllProductsSlice(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const GetAllProductsTypesSlice = createAsyncThunk(
	'product/types',
	async (data, thunkAPI) => {
		try {
			let token = GetUserToken(thunkAPI);
			return await ProductService.GetAllProductsTypesSlice(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const GetSingleProductsSlice = createAsyncThunk(
	'product/single',
	async (product_id, thunkAPI) => {
		try {
			let token = GetUserToken(thunkAPI);
			return await ProductService.GetSingleProductsSlice(product_id, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const CreateProductMethod = createAsyncThunk(
	'product/create',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await ProductService.CreateProductMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const UpdateProductMethod = createAsyncThunk(
	'product/update',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await ProductService.UpdateProductMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const DeleteProductSlice = createAsyncThunk(
	'product/delete',
	async (product_id, thunkAPI) => {
		try {
			let token = GetUserToken(thunkAPI);
			return await ProductService.DeleteProductSlice(product_id, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

const ProductSlice = createSlice({
	name: 'products',
	initialState: {
		product: [],
		products: [],
		product_types:[],
		isSuccess: false,
		isError: false,
		isLoading: false,
		errors: false,
		responseCode: false,
		message: null,
	},
	reducers: {
		productReset: (state) => {
			state.isSuccess = false;
			state.isError = false;
			state.isLoading = false;
			state.errors = false;
			state.responseCode = false;
			state.message = null;
		},
	},
	extraReducers: {
		[GetAllProductsSlice.pending]: (state) => {
			state.isLoading = true;
		},
		[GetAllProductsSlice.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.products = action.payload;
		},
		[GetAllProductsSlice.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},
		[GetAllProductsTypesSlice.pending]: (state) => {
			state.isLoading = true;
		},
		[GetAllProductsTypesSlice.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.product_types = action.payload;
		},
		[GetAllProductsTypesSlice.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[GetSingleProductsSlice.pending]: (state) => {
			state.isLoading = true;
		},
		[GetSingleProductsSlice.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.product = action.payload;
		},
		[GetSingleProductsSlice.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[CreateProductMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[CreateProductMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.product = action.payload;
		},
		[CreateProductMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[UpdateProductMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[UpdateProductMethod.fulfilled]: (state, action) => {
			state.isLoading = false;
			state.isSuccess = true;
			state.product = action.payload;
		},
		[UpdateProductMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},
		
		[DeleteProductSlice.pending]: (state) => {
			state.isLoading = true;
		},
		[DeleteProductSlice.fulfilled]: (state) => {
			state.isLoading = false;
			state.isSuccess = true;
		},
		[DeleteProductSlice.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},
	},
});

export const { productReset } = ProductSlice.actions;
export default ProductSlice.reducer;
