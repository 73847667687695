import {InputAdornment, Avatar, Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, ToggleButton, Typography } from '@mui/material';

import { useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { Mail, VerifiedUser, VpnKey } from '@material-ui/icons';
import image from '../../layouts/images/login/73208-jurupoles-login.gif';
import styled from 'styled-components';
import { SubmitButton } from '../../layouts/Styled/buttons/submitButton';
import * as yup from 'yup';
import { RegisterUserMethod, authReset } from '../../redux/AuthSlice/AuthSlice';
import { GetAllStatesMethod, GetAllProductTypesMethod } from '../../Components/HelperMethods/APISMethodsHelper';

// validate the form in login
const validationSchema = yup.object({
	company_name: yup.string('Enter Your Campany Name').required('Your Name Is Required'),
	company_email: yup
		.string('Enter your Company Email')
		.email('Enter a valid Company Email')
		.required('Email is required'),
	password: yup
		.string('Enter your password')
		.min(8, 'Password should be of minimum 8 characters length')
		.required('Password is required'),
});

// start a login function
const Register = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const [form, setForm] = useState(false);
	const { all_states } = useSelector((state) => state.all_states);
	const AllStates = all_states?.data?.states;
	const { user, isError, isSuccess, isLoading, errors, resopnseCode } =
	  useSelector((state) => state.auth);
	// initial value for form
	const formik = useFormik({
	initialValues: {
		company_name: '',
		company_email: '',
		head_office_address: '',
		is_delivery: false,
		delivery_time: '',
		password: '',
		covered_states: ['all'],
		logo: null,
	},
	// validationSchema: validationSchema,
	onSubmit: async (values) => {
		const formData = new FormData();
		formData.append('company_name', values?.company_name)
		formData.append('company_email', values?.company_email)
		formData.append('head_office_address', values?.head_office_address)
		formData.append('is_delivery', values?.is_delivery ? 1 : 0)
		if (values?.is_delivery)
			formData.append('delivery_time', values?.delivery_time)
		formData.append('password', values?.password)
		formData.append('covered_states', values?.covered_states)
		// formData.append('covered_states', values?.covered_states)
		if (values?.logo)
			formData.append('logo', values?.logo)

		setForm(true);
		await dispatch(RegisterUserMethod(formData));
		await dispatch(authReset());
	  }
	});

	const ShowUserMethod = async () => {
		// await GetAllStatesMethod(dispatch);
	}

	useEffect(() => {
		ShowUserMethod()
	}, [dispatch])
  
	useEffect(() => {
	  if (isError) {
		errors.map((error) => {
		  toast.error(error?.msg);
		});
	}
	if (isSuccess && form) {
		setForm(false);
		navigate("/");
		// localStorage.setItem('auth', JSON.stringify(user));
		// window.location.reload();
	}
		dispatch(authReset());
	}, [isError, errors, isSuccess, resopnseCode, dispatch]);
  
	const handelUploadPhoto = (e) => {
	  console.log(e);
	};

	console.log(formik.values)
  
	// the data return
	return (
	  <LoginContainer
		sx={{ padding: { xs: "5px", sm: "20px 10px", md: "50px 202px" } }}
	  >
		<Grid style={{ height: "100%" }} container spacing={2}>
		  <Grid
			item
			sx={{
			  display: { xs: "none", sm: "none", md: "flex" },
			  height: "100%",
			  overflow: "hidden",
			  justifyContent: "center"
			}}
			xs={0}
			sm={12}
			md={6}
		  >
			<img
			  src={image}
			  alt="Login Animation"
			  style={{
				margin: "auto",
				maxWith: "100%",
				maxHeight: "100%"
			  }}
			/>
		  </Grid>
		  <Grid item xs={12} sm={12} md={6}>
			<Typography variant="h2" align="center" className="login-text">
			  Register
			</Typography>
			<form onSubmit={formik.handleSubmit} mt={3} autocomplete="off">
			  <Grid container>
				<Grid item mb={2} xs={12}>
				  <TextField
					fullWidth
					id="input-with-icon-textfield"
					name="company_name"
					label="Company Name"
					InputProps={{
					  startAdornment: (
						<InputAdornment position="start">
						  {/* <AbcIcon /> */}
						</InputAdornment>
					  )
					}}
					value={formik.values.company_name}
					onChange={formik.handleChange}
					error={formik.touched.company_name && Boolean(formik.errors.company_name)}
				  />
				</Grid>
				<Grid item mb={2} xs={12}>
					<TextField
						fullWidth
						id="input-with-icon-textfield"
						name="company_email"
						label="Company Email"
						type="company_email"
						autocomplete="false"
						InputProps={{
						startAdornment: (
							<InputAdornment position="start">
							<Mail />
							</InputAdornment>
						)
						}}
						value={formik.values.company_email}
						onChange={formik.handleChange}
						error={formik.touched.company_email && Boolean(formik.errors.company_email)}
					/>
				</Grid>
				{/* <Grid item mb={2} xs={12}>
					<TextField
					  fullWidth
					  id="input-with-icon-textfield"
					  name="logo"
					  label="LOGO"
					  type="file"
					  onChange={(e) => handelUploadPhoto(e)}
					  InputProps={{
						startAdornment: (
						  <InputAdornment position="start">
							<AddAPhotoIcon />
						  </InputAdornment>
						)
					  }}
					/>
				</Grid> */}
				<Grid item mb={2} xs={12}>
					<TextField
					fullWidth
					id="input-with-icon-textfield"
					name="password"
					type="password"
					label="Password"
					autocomplete="false"
					InputProps={{
						startAdornment: (
						<InputAdornment position="start">
							<VerifiedUser />
						</InputAdornment>
						)
					}}
					value={formik.values.password}
					onChange={formik.handleChange}
					error={
						formik.touched.password && Boolean(formik.errors.password)
					}
					/>
				</Grid>
				<Grid item md={6} sm={6} xs={12} mb={2}>
					<TextField
						fullWidth
						InputLabelProps={{
							shrink: true,
						}}
						id="head_office_address"
						name="head_office_address"
						label="Head Office Address"
						value={formik.values?.head_office_address || null}
						onChange={formik.handleChange}
					/>
				</Grid>
				{/* <Grid item md={6} sm={6} xs={12} mb={2}>
					<TextField
						fullWidth
						type="file"
						id="logo"
						name="logo"
						label="Avatar"
						onChange={(e) => handleUploadImage(e)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
				</Grid> */}
				<Grid item md={6} sm={6} pl={2} xs={12} mb={2}>
					<FormGroup>
						<FormControlLabel
							value={formik.values?.is_delivery || false}
							id="is_delivery"
							name="is_delivery"
							onChange={formik.handleChange}
							control={<Checkbox defaultChecked />} label="Delivery" />
					</FormGroup>
				</Grid>
				{formik.values?.is_delivery && (
					<>
						<Grid item md={6} sm={6} xs={12} mb={2}>
							<TextField
								fullWidth
								InputLabelProps={{
									shrink: true,
								}}
								id="delivery_time"
								name="delivery_time"
								label="Delivery Time"
								value={formik.values?.delivery_time || null}
								onChange={formik.handleChange}
							/>
						</Grid>
					</>
				)}
				{/* <Grid item mb={2} md={6} xs={12}>
					<FormControl style={{width:"100%"}}>
						<InputLabel id="demo-multiple-name-label">Covered States</InputLabel>
						<Select
							labelId="demo-multiple-name-label"
							multiple
							id="demo-multiple-name"
							name="covered_states"
							value={formik.values?.covered_states || []}
							onChange={formik.handleChange}
							input={<OutlinedInput label="covered_states" />}
						>
							<MenuItem
								value="all"
							>
								All States
							</MenuItem>
							{AllStates?.map((state) => (
								<MenuItem
									key={state.id}
									value={state.abbr}
								>
									{state.name}
								</MenuItem>
							))}
						</Select>
					</FormControl>
				</Grid> */}
			</Grid>
  
			  <SubmitButton
				fullWidth
				variant="outlined"
				p={3}
				style={{ margin: "0 0 15px 0" }}
			  >
				Register
			  </SubmitButton>
  
			  <Typography variant="p" align="center" color="info">
				You Already Have An Account ?
				<Link
				  className="sign-up text-sub"
				  to="/login"
				  mt={3}
				  xs={{ textAlign: "center" }}
				>
				  {" "}
				  Sing in
				</Link>
			  </Typography>
			</form>
		  </Grid>
		</Grid>
	  </LoginContainer>
	);
  };
  
  const LoginContainer = styled.div`
	.login-text {
	  margin: 0 0 50px 0;
	  font-weight: bold;
	  color: var(--main-color)
	}
  `;
  export default Register;
  