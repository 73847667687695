import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from './AuthService';
import { GetErrorMessage, GetErrorsObj, GetResponseCode } from '../../Components/HelperMethods/ReturnErrors';

const initialState = {
	mainUser: JSON.parse(localStorage.getItem('auth'))?.data || null,
	user: null,
	isSuccess: false,
	isError: false,
	isLoading: false,
	responseCode: 0,
	errors: [],
};

export const LoginUserMethod = createAsyncThunk(
	'auth/login',
	async (data, thunkAPI) => {
		try {
			return await AuthService.LoginUserMethod(data);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const RegisterUserMethod = createAsyncThunk(
	'auth/register',
	async (data, thunkAPI) => {
		try {
			return await AuthService.RegisterUserMethod(data);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const LogoutUserMethod = createAsyncThunk(
	'auth/logout',
	async (_, thunkAPI) => {
		try {
			return await AuthService.LogoutUserMethod();
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const UpdateUserInformationMethod = createAsyncThunk(
	'auth/update',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await AuthService.UpdateUserInformationMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);

export const ShowUserInformationMethod = createAsyncThunk(
	'auth/show',
	async (data, thunkAPI) => {
		try {
			let token = thunkAPI.getState().auth?.mainUser?.token;
			return await AuthService.ShowUserInformationMethod(data, token);
		} catch (error) {
			return thunkAPI.rejectWithValue(error);
		}
	},
);



const AuthSlice = createSlice({
	name: 'auth',
	initialState,
	reducers: {
		authReset: (state) => {
			state.isSuccess = false;
			state.isError = false;
			state.isLoading = false;
			state.errores = [];
		},
	},
	extraReducers: {
		[LoginUserMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[LoginUserMethod.fulfilled]: (state, action) => {
			state.isLoadin = false;
			state.isSuccess = true;
			state.user = action.payload;
		},
		[LoginUserMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = action.payload?.response?.data?.errors;
			state.responseCode = action.payload?.response?.status;
		},

		[RegisterUserMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[RegisterUserMethod.fulfilled]: (state, action) => {
			state.isLoadin = false;
			state.isSuccess = true;
			state.user = action.payload;
		},
		[RegisterUserMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = action.payload?.response?.data?.errors;
			state.responseCode = action.payload?.response?.status;
		},

		[LogoutUserMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[LogoutUserMethod.fulfilled]: (state, action) => {
			state.isLoadin = false;
			state.isSuccess = true;
		},
		[LogoutUserMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = action.payload?.response?.data?.errors;
			state.responseCode = action.payload?.response?.status;
		},
		
		[UpdateUserInformationMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[UpdateUserInformationMethod.fulfilled]: (state, action) => {
			state.isLoadin = false;
			state.isSuccess = true;
			state.user = action.payload;
		},
		[UpdateUserInformationMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},

		[ShowUserInformationMethod.pending]: (state) => {
			state.isLoading = true;
		},
		[ShowUserInformationMethod.fulfilled]: (state, action) => {
			state.isLoadin = false;
			state.isSuccess = true;
			state.user = action.payload;
		},
		[ShowUserInformationMethod.rejected]: (state, action) => {
			state.isLoading = false;
			state.isError = true;
			state.errors = GetErrorsObj(action);
			state.message = GetErrorMessage(action);
			state.responseCode = GetResponseCode(action);
		},
	},
});

export const { authReset } = AuthSlice.actions;
export default AuthSlice.reducer;
