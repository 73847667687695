import {
	GetAllProductsSlice,
	productReset,
	GetAllProductsTypesSlice
} from '../../redux/ProjectSlice/ProductsSlice';
import { AllStatesMethod, stateReset } from '../../redux/StateSlice/StateSlice';

export const GetAllStatesMethod = async (dispatch) => {
	await dispatch(AllStatesMethod());
	await dispatch(stateReset());
};

export const GetAllProductsMethod = async (dispatch, params) => {
	await dispatch(GetAllProductsSlice(params));
	await dispatch(productReset());
};

export const GetAllProductTypesMethod = async (dispatch, params) => {
	await dispatch(GetAllProductsTypesSlice(params));
	await dispatch(productReset());
};
