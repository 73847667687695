import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { NavLink, Outlet, useNavigate } from 'react-router-dom';
import { Button, Grid } from '@mui/material';
import { AccountCircle, Add, Create } from '@material-ui/icons';
import { Inventory, Logout } from '@mui/icons-material';
import image from '../layouts/images/boxbyldLogo.png';
import { useLocation } from 'react-router-dom';

const drawerWidth = 240;

const logoStyle = {
	width: '100%',
	height: '100%',
	padding: '7px',
}


const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen,
	}),
	overflowX: 'hidden',
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`,
	},
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'flex-end',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	zIndex: theme.zIndex.drawer + 1,
	transition: theme.transitions.create(['width', 'margin'], {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen,
	}),
	...(open && {
		marginLeft: drawerWidth,
		width: `calc(100% - ${drawerWidth}px)`,
		transition: theme.transitions.create(['width', 'margin'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.enteringScreen,
		}),
	}),
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme),
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme),
	}),
}));

export default function MiniDrawer() {
	const location = useLocation();

	const theme = useTheme();
	const [open, setOpen] = React.useState(true);

	const handleDrawerOpen = () => {
		setOpen(true);
	};

	const handleDrawerClose = () => {
		setOpen(false);
	};

	const navigate = useNavigate();

	const HandleRemoveStorage = () => {
		localStorage.removeItem('auth');
		navigate('/login');
	};

	const LogoutUserMethod = () => {
		localStorage.removeItem('auth');
		// navigate('/login')
		window.location.reload()
	}


	return (
		<Box sx={{ display: 'flex' }}>
			<CssBaseline />
			<AppBar className="navbar bg-light text-dark" position="fixed" open={open}>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						sx={{
							marginRight: 5,
							...(open && { display: 'none' }),
						}}
					>
						<MenuIcon />
					</IconButton>
					<NavLink to="/" className="text-light">
						<Typography variant="h6" color="light"  noWrap component="div">
							Distributor
						</Typography>
					</NavLink>
				</Toolbar>
			</AppBar>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader className='slidpar-header'>
					{theme.direction !== 'rtl' && (
						<NavLink to={''} >
							<img src={image} alt='logo' style={logoStyle} className='LogoStyle' />
						</NavLink>
					)}
					<IconButton onClick={handleDrawerClose} >
						{theme.direction === 'rtl' ? (
							<>
								<ChevronRightIcon />
							</>
						) : (
							<>
								<ChevronLeftIcon />
							</>
						)}
					</IconButton>
				</DrawerHeader>
				<Divider />
				<List className="left-navbar-links">
					<ListItem disablePadding sx={{ display: 'block' }}>
						<NavLink to="products">
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								className={location.pathname.split('/')[1] === 'products' ? 'bg-main text-light' : ''}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
									className={location.pathname.split('/')[1] === 'products' ? 'bg-main text-light' : ''}
								>
									<Inventory />
								</ListItemIcon>
								<ListItemText
									primary="Products"
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</NavLink>
						<NavLink to="profile">
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								className={location.pathname.split('/')[1] === 'profile' ? 'bg-main text-light' : ''}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
									className={location.pathname.split('/')[1] === 'profile' ? 'bg-main text-light' : ''}
								>
									<AccountCircle />
								</ListItemIcon>
								<ListItemText
									primary="Profile"
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</NavLink>
					</ListItem>
					<ListItem className="logout-link" disablePadding sx={{ display: 'block' }}>
						<NavLink to="#" >
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
								onClick={(e) => LogoutUserMethod()}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									<Logout />
								</ListItemIcon>
								<ListItemText
									primary="Logout"
									sx={{ opacity: open ? 1 : 0 }}
								/>
							</ListItemButton>
						</NavLink>
						{/* <Button fullWidth onClick={(e) => HandleRemoveStorage()}>
								<ListItemButton
									sx={{
										minHeight: 48,
										justifyContent: open ? 'initial' : 'center',
										px: 2.5,
									}}
									fullWidth
									className="text-dark"
								>
									<ListItemIcon
										fullWidth
										sx={{
											minWidth: 0,
											mr: open ? 3 : 'auto',
											justifyContent: 'center',
											color: "#fff"
										}}
									>
										<Logout />
									</ListItemIcon>
									<ListItemText
										primary="Logout"
										sx={{ opacity: open ? 1 : 0, color: '#fff' }}
									/>
								</ListItemButton>
							</Button> */}
					</ListItem>
				</List>
				{/* <Divider /> */}
				{/* <List>
					{['Inbox', 'Starred', 'Send email', 'Drafts'].map((text, index) => (
						<ListItem key={text} disablePadding sx={{ display: 'block' }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5,
								}}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 3 : 'auto',
										justifyContent: 'center',
									}}
								>
									{index % 2 === 0 ? <InboxIcon /> : <MailIcon />}
								</ListItemIcon>
								<ListItemText primary={text} sx={{ opacity: open ? 1 : 0 }} />
							</ListItemButton>
						</ListItem>
					))}
				</List> */}
			</Drawer>
			<Box component="main" sx={{ flexGrow: 1, p: 3 }}>
				{/* <DrawerHeader /> */}
				<Outlet />
			</Box>
		</Box>
	);
}
