import { Edit, Update, VisibilityRounded } from '@material-ui/icons';
import {
	AttachEmail,
	DriveFileRenameOutline,
	Roofing,
	SolarPower,
} from '@mui/icons-material';
import { Avatar, Box, Button, ButtonGroup, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, InputLabel, MenuItem, Modal, OutlinedInput, Select, TextField, ToggleButton, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import styled from 'styled-components';
import LoadingAnimation from '../../Components/LoadingAnimation';
import { SubButton, SubmitButton } from '../../layouts/Styled/buttons/submitButton';
import { FormContainer } from '../../layouts/Styled/buttons/FormContainer';
import { HeaderStyle2, ImagePopUpstyle } from '../../layouts/Styled/HeaderStyle';
import CheckIcon from '@mui/icons-material/Check';
import { ShowUserInformationMethod, UpdateUserInformationMethod, authReset } from '../../redux/AuthSlice/AuthSlice';
import { HandleSuccessNotifications, HendleErrorsNotifications } from '../../Components/HelperMethods/HandleNotifications';
import { toast } from 'react-toastify';
import { GetAllStatesMethod, GetAllProductTypesMethod } from '../../Components/HelperMethods/APISMethodsHelper';

function ShowProfile() {
	const { user, isLoading, isError, errors, message, isSuccess, responseCode } = useSelector((state) => state.auth);
	const dispatch = useDispatch();
	const info = user?.data || [];
	const [data, setData] = useState({});
	const [fetch, setFetch] = useState(false);
	const [form, setForm] = useState(false);

	const { all_states } = useSelector((state) => state.all_states);
	const AllStates = all_states?.data?.states;

	const handleUpdatedata = (e) => {
		let { name, value } = e.target;
		if (name === 'status')
			setData({ ...data, reason: '' })
		setData({ ...data, [name]: value });
		console.log(data)
	}
	const handleSubmit = async (e) => {
		e.preventDefault();
		setForm(true);
		const formData = new FormData();
		formData.append('user_id', data?.user_id)
		formData.append('company_name', data?.company_name)
		formData.append('company_email', data?.company_email)
		formData.append('head_office_address', data?.head_office_address)
		formData.append('is_delivery', data?.is_delivery)
		formData.append('delivery_time', data?.delivery_time)
		formData.append('covered_states', data?.covered_states)

		if (data?.logo)
			formData.append('logo', data?.logo)

		await dispatch(UpdateUserInformationMethod({ data: formData, user_id: info?.id }))
		await ShowUserMethod()
		await dispatch(authReset());
		// await setFetch(true)
	}


	const handleUploadImage = (e) => {
		let { name } = e.target
		let value = e.currentTarget.files[0];
		setData({ ...data, [name]: value });
	};

	const SetFormData = () => {
		console.log(info)
		if (info) {
			setData({
				user_id: info?.id,
				company_name: info?.company_name || '',
				company_email: info?.company_email || '',
				head_office_address: info?.head_office_address || '',
				is_delivery: info?.is_delivery || false,
				delivery_time: info?.delivery_time || 0,
				covered_states: info?.covered_states || [],
			})
		}
	}
	const ShowUserMethod = async () => {
		await GetAllStatesMethod(dispatch);
		await dispatch(ShowUserInformationMethod())
		await dispatch(authReset())
		await setFetch(true)
		// await SetFormData()
	}

	useEffect(() => {
		ShowUserMethod()
	}, [dispatch])

	useEffect(() => {
		setFetch(false)
		SetFormData()
	}, [fetch])

	const CallSuccessMethod = async () => {
		HandleSuccessNotifications(toast, 'Data Updated Successfully')
	}

	useEffect(() => {
		if (isError && form) {
			setForm(false);
			HendleErrorsNotifications(toast, errors, message);
		}

		if (form && isSuccess) {
			setForm(false);
			CallSuccessMethod()
		}
	}, [form, isSuccess, dispatch, isError, isLoading, message, errors, responseCode])

	const [current_image, setCurrentImage] = useState(null)

	const handleShowImage = (e, path) => {
		e.preventDefault();
		setCurrentImage(path);
		handleOpenImage()
	}

	const [openImage, setOpenImage] = useState(false);
	const handleOpenImage = () => setOpenImage(true);
	const handleCloseImage = () => setOpenImage(false);


	console.log(data)

	return (
		<ShowContainer>
			{isLoading ? (
				<>
					<LoadingAnimation />
				</>
			) : (
				<>
					<Grid className="show" container spacing={3} columns={12} mt={3}>
						<Grid item lg={3} md={4} sm={12} sx={{ padidng: '0' }}>
							{console.log(data?.current_logo)}
							<Avatar
								src={data?.current_logo}
								sx={{
									width: '20vh',
									height: '20vh',
									display: 'flex',
									margin: 'auto',
								}}
							/>
							<Grid container spacing={2} mt={3}>
								<Grid item md={12}>
									<hr />
								</Grid>
								<Grid item xs={12}>
									<Typography variant="p" style={{ width: '100%' }} fullWidth>
										<DriveFileRenameOutline pt={1} ></DriveFileRenameOutline>
										<Typography className="company_name" variant="span" ml={2}>
											{info?.company_name}
										</Typography>
									</Typography>
								</Grid>
								<Grid item xs={12}>
									<Typography variant="p" align="center">
										<AttachEmail pt={1} ></AttachEmail>
										<Typography className="company_name" variant="span" ml={2}>
											{info?.company_email}
										</Typography>
									</Typography>
								</Grid>
							</Grid>
						</Grid>
						<Grid item lg={9} md={8} sm={12}>
							<FormContainer onSubmit={handleSubmit}>
								<HeaderStyle2>
									<Edit /> Edit
								</HeaderStyle2>
								<Grid container spacing={2}>
									<Grid item md={6} sm={6} xs={12} mb={3}>
										<TextField
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
											id="company_name"
											name="company_name"
											label="Name"
											value={data?.company_name || null}
											onChange={(e) => handleUpdatedata(e)}
										/>
									</Grid>
									<Grid item md={6} sm={6} xs={12} mb={3}>
										<TextField
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
											id="company_email"
											name="company_email"
											label="E-mail"
											value={data?.company_email || null}
											onChange={(e) => handleUpdatedata(e)}
										/>
									</Grid>
									<Grid item md={6} sm={6} xs={12} mb={3}>
										<TextField
											fullWidth
											InputLabelProps={{
												shrink: true,
											}}
											id="head_office_address"
											name="head_office_address"
											label="Head Office Address"
											value={data?.head_office_address || null}
											onChange={(e) => handleUpdatedata(e)}
										/>
									</Grid>
									{/* <Grid item md={6} sm={6} xs={12} mb={3}>
										<TextField
											fullWidth
											type="file"
											id="logo"
											name="logo"
											label="Avatar"
											onChange={(e) => handleUploadImage(e)}
											InputLabelProps={{
												shrink: true,
											}}
										/>
									</Grid> */}
									<Grid item md={6} sm={6} xs={12} mb={3}>
										<FormGroup>
											<FormControlLabel
												value={data?.is_delivery || false}
												id="is_delivery"
												name="is_delivery"
												onChange={(e) => handleUpdatedata(e)}
												control={<Checkbox defaultChecked />} label="Delivery" />
										</FormGroup>
									</Grid>
									{data?.is_delivery === 1 && (
										<>
											<Grid item md={6} sm={6} xs={12} mb={3}>
												<TextField
													fullWidth
													InputLabelProps={{
														shrink: true,
													}}
													id="delivery_time"
													name="delivery_time"
													label="Delivery Time"
													value={data?.delivery_time || null}
													onChange={(e) => handleUpdatedata(e)}
												/>
											</Grid>
										</>
									)}
									<Grid item mb={2} md={6} xs={12}>
										<FormControl style={{width:"100%"}}>
											<InputLabel id="demo-multiple-name-label">Covered States</InputLabel>
											<Select
												labelId="demo-multiple-name-label"
												multiple
												id="demo-multiple-name"
												name="covered_states"
												value={data.covered_states || []}
												onChange={(e) => handleUpdatedata(e)}
												input={<OutlinedInput label="covered_states" />}
											>
												<MenuItem
													value="all"
												>
													All States
												</MenuItem>
												{AllStates?.map((state) => (
													<MenuItem
														key={state.id}
														value={state.abbr}
													>
														{state.name}
													</MenuItem>
												))}
											</Select>
										</FormControl>
									</Grid>
									<SubmitButton startIcon={<Edit />} type="submit">
										Update
									</SubmitButton>
								</Grid>
							</FormContainer>

							{/* style={{ display: 'flex', justifyContent: 'center' }} */}
							<Modal
								open={openImage}
								onClose={handleCloseImage}
								aria-labelledby="modal-modal-title"
								aria-describedby="modal-modal-description"
							>
								<Box sx={ImagePopUpstyle}>
									<img src={current_image} className='image_show' style={{ width: '100%', height: '100%' }} />
								</Box>
							</Modal>
						</Grid>
					</Grid>
				</>
			)}
		</ShowContainer>
	);
}
const ShowContainer = styled.div`
	.user-name {
		text-transform: capitalize;
	}
	.edit-button {
		color: var(--text-main);
	}
	.css-iajp3t-MuiButtonGroup-root {
		width: 100%;
	}
`;
export default ShowProfile;
