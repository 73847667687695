const DISTIBUTER_PORTAL_URL = 'https://distributor.boxbyld.tech';


export const AUTH_URL = `${DISTIBUTER_PORTAL_URL}/api/auth`;
export const USER_AUTH = `${DISTIBUTER_PORTAL_URL}/api/user`;


export const PRODUCTS_URL = `${DISTIBUTER_PORTAL_URL}/api/product`;

export const STATES_API = `https://crm.boxbyld.tech/api/v1/countries/country-api/231`;
